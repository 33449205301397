var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "app-header navbar"
  }, [_vm.toastWelcome ? _c('div', {
    staticClass: "toast"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_vm._v(" Welcome " + _vm._s(this.name) + " ")]), _c('div', {
    staticClass: "ml-3"
  }, [_c('svg', {
    staticClass: "w-3 h-3",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 14 14"
    },
    on: {
      "click": _vm.closeToast
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
    }
  })])])])]) : _vm._e(), _c('button', {
    staticClass: "navbar-toggler mobile-sidebar-toggler d-lg-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.mobileSidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa fa-bars text-gray-500",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('button', {
    staticClass: "ml-3 navbar-toggler border-0 focus:outline-none sidebar-toggler d-md-down-none",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.sidebarToggle
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-bars text-2xl text-gray-500"
  })]), _c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }), _c('nav', {
    staticClass: "ml-auto px-4"
  }, [_c('t-dropdown', {
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function (_ref) {
        var mousedownHandler = _ref.mousedownHandler,
          focusHandler = _ref.focusHandler,
          blurHandler = _ref.blurHandler,
          keydownHandler = _ref.keydownHandler,
          isShown = _ref.isShown;
        return _c('div', {}, [_c('button', {
          staticClass: "flex items-start focus:outline-none text-left",
          class: {
            'border-0 ': isShown
          },
          attrs: {
            "id": "user-menu",
            "aria-label": "User menu",
            "aria-haspopup": "true"
          },
          on: {
            "mousedown": mousedownHandler,
            "focus": focusHandler,
            "blur": blurHandler,
            "keydown": keydownHandler
          }
        }, [_c('i', {
          staticClass: "fa-regular text-3xl mr-2 font-light fa-circle-user"
        })])]);
      }
    }, {
      key: "default",
      fn: function (_ref2) {
        var hide = _ref2.hide,
          blurHandler = _ref2.blurHandler;
        return _c('div', {}, [_c('button', {
          staticClass: "block w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out ut hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
          attrs: {
            "role": "menuitem"
          },
          on: {
            "blur": blurHandler,
            "click": _vm.showDetail
          }
        }, [_c('span', {
          staticClass: "cursor-pointer text-ellipsis overflow-hidden"
        }, [_c('i', {
          staticClass: "fa fa-user mr-2"
        }), _vm._v(" " + _vm._s(_vm.auth ? _vm.auth.username : '-') + " ")])]), _c('button', {
          staticClass: "block w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out ut hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
          attrs: {
            "role": "menuitem"
          },
          on: {
            "blur": blurHandler,
            "click": function ($event) {
              return _vm.actionLogout();
            }
          }
        }, [_c('span', {
          staticClass: "cursor-pointer text-sm"
        }, [_c('i', {
          staticClass: "fa-solid fa-right-from-bracket mr-2"
        }), _vm._v("Logout ")])])]);
      }
    }])
  })], 1), _c('t-modal', {
    ref: "confirmationModalLogOut"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('label', {
    staticClass: "text-center font-semibold"
  }, [_vm._v("Are You Sure?")]), _c('span', {
    staticClass: "block mb-2"
  }, [_c('i', {
    staticClass: "fa-solid fa-exclamation text-gray-400 border py-3.5 px-4 text-3xl rounded-full"
  })]), _c('p', {
    staticClass: "text-center text-gray-500"
  }, [_vm._v("Logout Now")])]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("Yes")])])]), _c('Toast')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }