<template>
  <header class="app-header navbar">
    <div v-if="toastWelcome" class="toast">
      <div class="flex justify-between items-center">
        <div> Welcome {{ this.name }} </div>
        <div class="ml-3">
          <svg @click="closeToast" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </div>
      </div>
    </div>
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      <i class="fa fa-bars text-gray-500" aria-hidden="true"></i>
    </button>
    <button
      class=" ml-3 navbar-toggler border-0 focus:outline-none sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle"
    >
    <i class="fa-solid fa-bars text-2xl text-gray-500"></i>
    </button>
    <b-link class="navbar-brand" to="/"> </b-link>


    <nav class="ml-auto px-4">
      <t-dropdown>
  <div
    slot="trigger"
    slot-scope="{
      mousedownHandler,
      focusHandler,
      blurHandler,
      keydownHandler,
      isShown
    }"
  >
    <button
      id="user-menu"
      class="flex items-start focus:outline-none text-left"
      :class="{ 'border-0 ': isShown }"
      aria-label="User menu"
      aria-haspopup="true"
      @mousedown="mousedownHandler"
      @focus="focusHandler"
      @blur="blurHandler"
      @keydown="keydownHandler"
    >
    <i class="fa-regular text-3xl mr-2 font-light fa-circle-user"></i>
  
    </button>
  </div>

  <div slot-scope="{ hide, blurHandler }">
    <button
      class="block w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out ut hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
      role="menuitem"
      @blur="blurHandler"
       @click="showDetail"
    >
    <span class="cursor-pointer text-ellipsis overflow-hidden ">
          <i class="fa fa-user mr-2"></i> {{ auth ? auth.username : '-'}}
    </span>
    </button>
    <button
      class="block w-full text-left px-4 py-3 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out ut hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
      role="menuitem"
      @blur="blurHandler"
      @click="actionLogout()"
    >
    <span class="cursor-pointer text-sm" >
      <i class="fa-solid fa-right-from-bracket mr-2"></i>Logout
    </span>
    </button>
  </div>
</t-dropdown>
    </nav>


    <t-modal ref="confirmationModalLogOut">
      <div class="text-sm flex-col text-center">
        <label class="text-center font-semibold">Are You Sure?</label>
        <span class="block mb-2">
          <i class="fa-solid fa-exclamation text-gray-400 border py-3.5 px-4 text-3xl rounded-full"></i>
        </span>
        <p class="text-center text-gray-500">Logout Now</p>
      </div>
      <div class="flex justify-center">
            <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" @click="cancel">Cancel</button>
            <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm" @click="confirm">Yes</button>
      </div>
    </t-modal>
    <Toast  />
  </header>
</template>
<script>
import{ mapState,mapMutations} from "vuex"
import Toast from '../../components/form/Toast.vue';
export default {
  name: "headers",
  components: {
    Toast
  },
  data() {
    return {
      name: "", 
      toastWelcome:false
    };
  },
  computed: {
    ...mapState({
      auth : (state) => state.auth.auth,
      isLogin:(state)=>state.auth.isAuthenticated,
    }),
  },
  mounted() {
    if (localStorage.getItem('showWelcomeToast')) {
      this.toastWelcome = true
      setTimeout(()=> {
            this.toastWelcome=false;
          },3500)
    }
      
  },
  methods: {
    ...mapMutations('auth',['setAuth',"setLogin"]),
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    sidebarMinimize(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-minimized");
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-mobile-show");
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("aside-menu-hidden");
    },
    closeToast(){
      this.toastWelcome=false;
    },
    confirm() {
      this.$refs.confirmationModalLogOut.hide();
      localStorage.clear();
      this.setLogin(false);
      this.setAuth({});
      window.location.reload();
    },
    cancel() {
      this.$refs.confirmationModalLogOut.hide();
    },
    actionLogout() {
      this.$refs.confirmationModalLogOut.show();
    },
    showDetail() {
      this.$router.push({
        path: "/settings/profile"
      });
    }
  },
  created: function() {
    this.name = localStorage.getItem("session_name");
    let auth = localStorage.getItem("session_auth");
    if (this.name && auth) {
      this.setAuth(JSON.parse(auth));
    }
  }
};
</script>
<style>
.toast {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 13px 20px;
    border-radius: 5px;
    color: white;
    background-color: #333;
    opacity: 1;
    z-index: 1000;
  }
  
.navbar-nav .nav-link,
.navbar-nav .navbar .dropdown-toggle,
.navbar .navbar-nav .dropdown-toggle {
  color: black;
}
</style>
